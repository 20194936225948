// src/components/HomeComponent.jsx
import React from "react";
import HeaderComponent from "./HeaderComponent";
import ReasonsComponent from "./ReasonsComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
const HomeComponent = () => {
  return (
    <div className="app">
      <HeaderComponent />
      <ReasonsComponent />
      <button onClick={() => (window.location.href = "/contact")}>
        Contattaci
      </button>
      <section className="d-flex flex-column align-items-center justify-content-center app-section text-dark pb-5">
        <div className="mt-5 container">
          <p>
            <strong>Not Only Cars SRL</strong>
          </p>
          <p>
            <strong>Partita IVA:</strong> 13470900963
          </p>
          <p>
            <strong>Email:</strong>{" "}
            <a href="mailto:srl@notonlycars.it">srl@notonlycars.it</a>
          </p>
          <p>
            <strong>Telefono:</strong>{" "}
            <a href="tel:+393384339021">+39 338 433 9021</a>
          </p>
          <div className="social-icons mt-3">
            <a
              href="https://www.instagram.com/notonlycars.srl"
              className="contact-link ig mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a
              href="https://www.linkedin.com/company/not-only-cars-srl"
              className="contact-link ln mx-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
          </div>
        </div>
        <button onClick={()=>window.location.href = "nocapp://"}>test</button>
      </section>
    </div>
  );
};

export default HomeComponent;
